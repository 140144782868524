&sitemap {
  ul{
    @include flex("", "fs");
    @include mq(sp) {
      display: block;
    }
    li {
      flex: 1;
      max-width: 25%;
      border-right: 1px dashed #999;
      border-left: 1px dashed #999;
      padding: 20px 30px;
      margin-right: -1px;
      @include mq(sp) {
        flex: none;
        width: 100%;
        max-width: unset;
        border-right: none;
        padding: 15px 20px;
        margin-right: 0;
      }
      h2 {
        @include fz(16);
        @include mq(sp) {
          @include fz(13);
        }
        a {
          font-weight: 500;
          &:hover {
            text-decoration: underline;
          }
        }
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
      + li {
        @include mq(sp) {
          margin-top: 30px;
        }
      }
    }
    + ul {
      margin-top: 30px;
    }
  }
}
