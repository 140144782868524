&footer {
  &__org {
    padding-top: 30px;
    padding-bottom: 30px;
    @include mq(sp) {
      padding-top: 40px;
      padding-bottom: 45px;
    }
    .c-inr {
      @include flex("c", "");
      @include mq(sp) {
        display: block;
      }
      > div {
        flex: 1;
        @include mq(sp) {
          flex: none;
          width: 100%;
        }
      }
      > ul {
        @include flex("c", "fs");
        @include mq(sp) {
          justify-content: space-between;
        }
        li {
          width: 74px;
          @include mq(sp) {
            width: 50px;
          }
          a {
            display: inline-block;
            @include hov-alpha;
          }
          &:not(:last-child) {
            margin-right: 40px;
            @include mq(sp) {
              margin-right: 0;
            }
          }
          &:nth-child(1) {
            width: 113px;
            @include mq(sp) {
              width: 77px;
            }
          }
          &:nth-child(3) {
            width: 111px;
            @include mq(sp) {
              width: 66px;
            }
          }
        }
      }
    }
    &__ttl {
      @include fz(26);
      font-weight: 500;
      line-height: 1.25;
      @include mq(sp) {
        @include fz(20);
        margin-bottom: 20px;
      }
      span {
        @include fz(20);
        font-weight: inherit;
        color: $col_main;
        display: inline-block;
        @include mq(sp) {
          @include fz(13);
        }
      }
    }
  }

  &__nav {
    background: $col_main;
    padding-top: 30px;
    padding-bottom: 25px;
    @include mq(sp) {
      padding-top: 35px;
      padding-bottom: 35px;
    }
    .c-inr {
      @include flex("c", "");
      > figure {
        @include mq(sp) {
          width: 175px;
          margin-bottom: 15px;
        }
      }
      > ul {
        margin-top: -10px;
        @include flex("c", "fs");
        li {
          @include fz(16);
          @include mq(sp) {
            width: 45%;
            @include fz(14);
            margin-top: 10px;
          }
          a {
            color: #fff;
            display: inline-block;
            @include hov-alpha;
          }
          &:not(:last-child) {
            margin-right: 50px;
            @include mq(sp) {
              margin-right: 0;
            }
          }
          &:nth-child(odd) {
            @include mq(sp) {
              margin-right: 10%;
            }
          }
        }
      }
    }
  }

  &__btm {
    padding-top: 20px;
    padding-bottom: 20px;
    &__inr {
      @include flex("c", "");
      @include mq(sp) {
        flex-direction: column-reverse;
        justify-content: center;
      }
      > small {
        @include fz(14);
        color: $col_main;
        text-align: center;
        display: block;
        @include mq(sp) {
          @include fz(10);
          margin-top: 15px;
        }
      }
      > ul {
        @include flex("", "fs");
        @include mq(sp) {
          justify-content: center;
        }
        li {
          @include fz(14);
          @include mq(sp) {
            @include fz(10);
          }
          a {
            &:hover {
              text-decoration: underline;
            }
          }
          &:not(:last-child) {
            margin-right: 1em;
          }
        }
      }
    }
  }
}
