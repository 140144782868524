@charset "UTF-8";

/*!
Coding style from FLOCSS
https://github.com/hiloki/flocss
 */

// ==========================================================================
// Foundation
// ==========================================================================

@import "foundation/function";
@import "foundation/setting";
@import "foundation/mixin";
@import "foundation/reset";
@import "foundation/base";

// ==========================================================================
// Layout
// ==========================================================================

.l- {
  @import "layout/_footer.scss";
  @import "layout/_header.scss";
  @import "layout/_main.scss";
  @import "layout/_navi.scss";
}

// ==========================================================================
// Object
// ==========================================================================

// -----------------------------------------------------------------
// Component
// -----------------------------------------------------------------

.c- {
  @import "object/component/_button.scss";
  @import "object/component/_container.scss";
  @import "object/component/_form.scss";
  @import "object/component/_list.scss";
  @import "object/component/_slick.scss";
  @import "object/component/_table.scss";
  @import "object/component/_text.scss";
  @import "object/component/_title.scss";
}

// -----------------------------------------------------------------
// Project
// -----------------------------------------------------------------

.p- {
  @import "object/project/_about.scss";
  @import "object/project/_contact.scss";
  @import "object/project/_insights.scss";
  @import "object/project/_news.scss";
  @import "object/project/_page.scss";
  @import "object/project/_post.scss";
  @import "object/project/_privacy.scss";
  @import "object/project/_related.scss";
  @import "object/project/_sitemap.scss";
  @import "object/project/_top.scss";
}

// -----------------------------------------------------------------
// Utility
// -----------------------------------------------------------------

.u- {
  @import "object/utility/_display.scss";
  @import "object/utility/_font.scss";
  @import "object/utility/_margin.scss";
  @import "object/utility/_talign.scss";
}
