&lead {
  @include fz(16);
  font-weight: 500;
  line-height: 1.75;
  margin-bottom: 60px;
  @include mq(sp) {
    @include fz(13);
    margin-bottom: 30px;
  }
}

&txt {
  @include fz(16);
  line-height: 1.75;
  margin-bottom: 45px;
  @include mq(sp) {
    @include fz(13);
  }
}
