&wrap {
  @include wrapper-box;
}

&inr {
  @include inner-box(1300);
  &--md {
    max-width: 900px;
  }
}

&clearfix {
  @include clearfix;
}