html {
  font-size: $base-font-size;
}

body {
  font-family: "Noto Sans CJK JP", "Noto Sans JP", sans-serif;
  @include fz(12);
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  color: $col_text;
  line-height: 1.5;
}

a {
  color: $col_text;
  text-decoration: none;
  outline: none;
  transition: opacity 0.2s;
  &[href^="tel:"] {
    @include mq(pc) {
      cursor: default;
      pointer-events: none;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

input,
textarea,
button,
select {
  font: inherit;
}

button {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

label {
  cursor: pointer;
}

sup,
sub {
  font-size: 0.75em;
}