&header {
  $header: &;

  width: 100%;
  background: rgba($color: #fff, $alpha: 0.9);
  padding: 30px 60px 30px 40px;
  position: fixed;
  top: 0;
  left: 0;
  transition: background 0.2s;
  z-index: 1000;
  @include mq(sp) {
    padding: 15px 25px 15px 20px;
  }
  &__inr {
    @include flex("c", "");
  }
  &__logo {
    @include mq(sp) {
      width: 175px;
    }
    a {
      display: inline-block;
    }
  }
  &__hum {
    width: 20px;
    height: 22px;
    position: relative;
    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background: #483d3d;
      position: absolute;
      left: 0;
      display: inline-block;
      transition: transform .3s;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
    span {
      content: "";
      width: 100%;
      height: 2px;
      background: #483d3d;
      margin: auto;
      position: absolute;
      inset: 0;
      display: inline-block;
      transition: opacity .3s;
    }
  }
  &.is-active {
    background: #fff;
    #{$header} {
      &__hum {
        &::before,
        &::after {
          margin: auto;
        }
        &::before {
          bottom: 0;
          transform: rotate(45deg);
        }
        &::after {
          top: 0;
          transform: rotate(-45deg);
        }
        span {
          opacity: 0;
        }
      }
    }
    .l-gnavi {
      opacity: 1;
      visibility: visible;
    }
  }
}
