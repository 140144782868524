&page {
  // common
  padding-bottom: 60px;
  margin-top: 106px;
  @include mq(sp) {
    padding-bottom: 50px;
    margin-top: 60px;
  }
  &-mv {
    background: $col_bg;
    background-position: right bottom;
    background-repeat: no-repeat;
    padding-top: 95px;
    padding-bottom: 70px;
    @include mq(sp) {
      padding-top: 80px;
      padding-bottom: 40px;
      background-size: auto 125px;
      background-position: right -163px bottom;
    }
    &--about {
      background-image: url(#{$img_path}/page/page-about-mv.svg);
    }
    &--news {
      background-image: url(#{$img_path}/page/page-news-mv.svg);
      background-position: right bottom -4px;
      @include mq(sp) {
        background-position: right -163px bottom -2px;
      }
    }
    &--insights {
      background-image: url(#{$img_path}/page/page-insights-mv.svg);
    }
    &--contact {
      background-image: url(#{$img_path}/page/page-contact-mv.svg);
      background-position: right bottom -4px;
      @include mq(sp) {
        background-position: right -163px bottom -2px;
      }
    }
    &__inr {
      height: 100%;
      padding-top: 55px;
      @include mq(sp) {
        padding-top: 0;
      }
    }
  }

  &-content {
    padding-top: 120px;
    position: relative;
    @include mq(sp) {
      padding-top: 60px;
    }
  }
}
