// ベースフォントサイズ
$base-font-size: 10px;

// breakpoint
$breakpoint_list: 767px, 1024px;

// カラー
$col_main: #007374;
$col_sub: #000;
$col_text: #483d3d;
$col_bg: #f1fdf9;

// 画像ファイルパス
$img_path: "/cms/wp-content/themes/ppp-2023/images";
$img_common_path: "/cms/wp-content/themes/ppp-2023/images/common";
