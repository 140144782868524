&list {
  &--news {
    li {
      a {
        width: 100%;
        text-decoration: none;
        color: inherit;
        border-bottom: 1px solid #d2d2d2;
        padding: 18px 0;
        display: inline-block;
        transition: background .3s;
        &:hover {
          background: #f8f8f8;
        }
        article {
          @include flex("c", "fs");
        }
      }
    }
    &__date {
      width: 5.5em;
      @include fz(18);
      color: #666;
      margin-right: 30px;

      @include mq(sp) {
        @include fz(15);
        margin-right: 15px;
      }
    }
    &__cat {
      width: 90px;
      @include fz(16);
      line-height: 1;
      color: #fff;
      text-align: center;
      margin-right: 25px;
      padding: 3px 0 2px;

      @include mq(sp) {
        width: 60px;
        margin-right: 0;
        @include fz(10);
      }

      &--news,
      &--articles {
        background: $col_main;
      }
      &--events,
      &--interviews {
        background: $col_sub;
      }
      &--speeches,
      &--updates {
        background: #000;
      }
    }
    &__ttl {
      @include fz(18);
      flex: 1;

      @include mq(sp) {
        width: 100%;
        @include fz(15);
        margin-top: 10px;
        flex: unset;
      }
    }
  }
  &--pagenavi {
    margin-top: 60px;
    .wp-pagenavi {
      @include flex("c", "c");
      > * {
        @include fz(16);
        padding: 5.5px 13px;
        + * {
          margin-left: 10px;
        }
      }
      span {
        color: #fff;
        background: $col_main;
        border: 1px solid $col_main;
      }
      a {
        border: 1px solid #000;
        &.previouspostslink,
        &.nextpostslink {
          border: none;
        }
      }
    }
  }
}