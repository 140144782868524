&privacy {
  ul {
    margin-bottom: 45px;
    li {
      @include fz(16);
      text-indent: -1em;
      padding-left: 1em;
      @include mq(sp) {
        @include fz(13);
      }
      &::before {
        content: "・";
      }
      > * {
        text-indent: initial;
      }
      a {
        &:not([class]) {
          text-decoration: underline;
          color: $col_main;
          &:hover {
            text-decoration: none;
          }
        }
        &.c-btn {
          margin-top: 10px;
          @include mq(sp) {
            @include fz(12);
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 1.5em;
      }
    }
  }
}
