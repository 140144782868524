&top {
  &-fv {
    background: url(#{$img_path}/top/top-fv-bg.svg) right 140px top 90px no-repeat;
    background-size: 968px 996px;
    padding-top: 180px;
    padding-bottom: 75px;
    margin-top: 20px;
    @include mq(1125) {
      background-position: right 40px top 90px;
    }
    @include mq(sp) {
      background-size: 98% auto;
      background-position: center 20px;
      padding-top: 126px;
      padding-bottom: 42px;
      margin-top: 40px;
    }
    &__ttl {
      margin-bottom: 125px;
      @include mq(sp) {
        margin-bottom: 40px;
      }
    }
    &__news {
      @include flex("", "fs");
      @include mq(sp) {
        display: block;
      }
      &__lt {
        width: 250px;
        margin-right: 4.6875%;
        @include mq(sp) {
          width: 100%;
          margin: 0 0 20px 0;
        }
      }
      &__cat {
        @include mq(sp) {
          margin-bottom: 20px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 5px 2%;
        }
        li {
          @include fz(18);
          @include mq(sp) {
            @include fz(11);
          }
          span {
            width: 100%;
            font-weight: 500;
            border-radius: 25px;
            padding: 10px 25px;
            @include flex("c", "");
            transition:
              color 0.2s,
              background-color 0.2s;
            cursor: pointer;
            user-select: none;
            @include mq(sp) {
              background: rgba($color: #aaaaaa, $alpha: 0.2);
              border-radius: 5px;
              padding-right: 5px;
              padding-left: 5px;
              justify-content: center;
            }
            &::after {
              @include mq(sp) {
                content: none !important;
              }
            }
            &:hover {
              color: #fff;
              background: $col_main;
              &::after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 0 6px 8px;
                border-color: transparent transparent transparent #fff;
              }
            }
          }
          &.is-current {
            span {
              color: #fff;
              background: $col_main;
              &::after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 0 6px 8px;
                border-color: transparent transparent transparent #fff;
              }
            }
          }
          &:not(:last-child) {
            margin-bottom: 10px;
            @include mq(sp) {
              margin-bottom: 0;
            }
          }
        }
      }
      &__gt {
        flex: 1;
      }
      &__link {
        @include fz(18);
        text-align: right;
        margin-top: 25px;
        @include mq(sp) {
          @include fz(13);
          margin-top: 30px;
        }
        a {
          font-weight: 500;
          color: $col_main;
          @include inline-flex("c", "fs");
          @include hov-alpha;
          &::after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 0 6px 8px;
            border-color: transparent transparent transparent $col_main;
            margin-left: 15px;
            transition: transform 0.2s;
            @include mq(sp) {
              border-width: 3px 0 3px 4px;
              margin-left: 6px;
            }
          }
        }
      }
    }
  }

  &-about {
    background: transparent linear-gradient(to right, #53bc99 0%, #2fa794 75%, #2aa493 85%, #008c8d 100%);
    padding-top: 60px;
    padding-bottom: 70px;
    @include mq(sp) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    &__inr {
      @include flex("", "fs");
      @include mq(sp) {
        display: block;
      }
    }
    &__lt {
      margin-right: 5.0781%;
      @include mq(sp) {
        margin-right: 0;
      }
      h2 {
        color: #fefed7;
        span {
          color: #fff;
        }
      }
    }
    &__gt {
      flex: 1;
      color: #fff;
    }
    &__ttl {
      @include fz(24);
      font-weight: 500;
      margin-bottom: 25px;
      @include mq(sp) {
        @include fz(19);
        margin-bottom: 10px;
      }
    }
    &__txt {
      @include fz(18);
      line-height: 2;
      @include mq(sp) {
        @include fz(13);
      }
    }
    &__link {
      margin-top: 10px;
      text-align: right;
      @include mq(sp) {
        text-align: center;
        margin-top: 20px;
      }
      a {
        border: none;
      }
    }
  }

  &-insights {
    overflow: hidden;
    @include container(top-insights);
    &__inr {
      @include flex("", "fs");
      @include mq(sp) {
        display: block;
      }
      > div {
        padding-top: 100px;
        padding-bottom: 80px;
        &:nth-child(1) {
          @include mq(sp) {
            padding-top: 30px;
            padding-bottom: 25px;
          }
        }
        &:nth-child(2) {
          @include mq(sp) {
            padding-top: 0;
            padding-bottom: 50px;
          }
        }
        &:nth-child(3) {
          @include mq(sp) {
            padding-top: 50px;
            padding-bottom: 0;
          }
          select {
            width: 100%;
            @include fz(16);
            border: 1px solid $col_main;
            padding: 5px;
            margin-bottom: 40px;
          }
        }
      }
    }
    &__col1 {
      width: 250px;
      position: relative;
      z-index: 1;
      @include mq(sp) {
        width: 100%;
      }
      &::before {
        @include mq(sp) {
          content: "";
          width: calc(100% + 40px);
          height: 100%;
          background: rgba($color: #eaeaea, $alpha: 0.3);
          position: absolute;
          top: 0;
          left: -20px;
          z-index: -1;
          display: inline-block;
        }
      }
      .p-top-ttl {
        @include mq(sp) {
          margin-bottom: 0;
        }
      }
    }
    &__cat {
      margin-bottom: 50px;
      &__ttl {
        @include fz(20);
        font-weight: 500;
        padding-bottom: 15px;
        margin-bottom: 20px;
        position: relative;
        z-index: 0;
        &::after {
          content: "";
          width: 500%;
          height: 1px;
          background: #aaa;
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: -1;
        }
      }
      &__list {
        > li {
          @include fz(18);
          > span {
            width: 100%;
            font-weight: 500;
            padding: 0 10px 0 5px;
            @include inline-flex("c", "");
            cursor: pointer;
            user-select: none;
          }
          &:not(:last-child) {
            margin-bottom: 20px;
          }
          dl {
            dt {
              font-weight: 500;
              color: $col_main;
              padding: 0 10px 0 5px;
              @include flex("c", "");
              cursor: pointer;
              user-select: none;
              button {
                width: 16px;
                height: 16px;
                position: relative;
                &::before,
                &::after {
                  content: "";
                  background: $col_main;
                  border-radius: 2px;
                  margin: auto;
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  display: inline-block;
                }
                &::before {
                  width: 100%;
                  height: 2px;
                }
                &::after {
                  width: 2px;
                  height: 100%;
                  transition: transform 0.2s;
                }
              }
              &.is-open {
                button {
                  &::after {
                    transform: rotate(90deg);
                  }
                }
              }
            }
            dd {
              display: none;
              ul {
                li {
                  margin-top: 20px;
                  span {
                    font-weight: 500;
                    color: #666;
                    padding: 0 10px 0 5px;
                    @include flex("c", "");
                    cursor: pointer;
                    user-select: none;
                    transition: color 0.2s;
                    &::after {
                      content: "";
                      width: 0;
                      height: 0;
                      border-style: solid;
                      border-width: 6px 0 6px 8px;
                      border-color: transparent transparent transparent transparent;
                      transition: border-color 0.2s;
                    }
                    &:hover {
                      color: $col_main;
                      &::after {
                        border-color: transparent transparent transparent $col_main;
                      }
                    }
                  }
                  &.is-current {
                    span {
                      color: $col_main;
                      &::after {
                        content: "";
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 6px 0 6px 8px;
                        border-color: transparent transparent transparent $col_main;
                      }
                    }
                  }
                }
              }
            }
          }
          &.is-current {
            > span {
              color: $col_main;
              &::after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 0 6px 8px;
                border-color: transparent transparent transparent $col_main;
              }
            }
          }
        }
      }
    }
    &__link {
      margin-left: 5px;
      @include mq(sp) {
        text-align: center;
        margin: 20px 0 0;
      }
    }
    &__col2 {
      width: 46.875%;
      padding: 0 5.4688%;
      position: relative;
      z-index: 0;
      @include mq(sp) {
        width: 100%;
      }
      &::before {
        content: "";
        width: 500%;
        height: 100%;
        background: rgba($color: #eaeaea, $alpha: 0.3);
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        display: inline-block;
        @include mq(sp) {
          width: calc(100% + 40px);
          inset: 0 0 0 -20px;
        }
      }
    }
    &__slide {
      .slick-arrow {
        width: 40px;
        height: 40px;
        font-size: 0;
        background: #fff;
        padding: 0;
        position: absolute;
        top: 30%;
        z-index: 1;
        transition: background 0.2s;
        @include flex("c", "c");
        @include mq(sp) {
          top: 25%;
        }
        &:hover {
          background: $col_main;
        }
        &.slick-prev {
          margin-left: -30px;
          left: 0;
          &::before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 8px 6px 0;
            border-color: transparent $col_main transparent transparent;
            margin-left: -4px;
            transition: border-color 0.2s;
          }
          &:hover {
            &::before {
              border-color: transparent #fff transparent transparent;
            }
          }
        }
        &.slick-next {
          margin-right: -30px;
          right: 0;
          &::before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 0 6px 8px;
            border-color: transparent transparent transparent $col_main;
            margin-right: -4px;
            transition: border-color 0.2s;
          }
          &:hover {
            &::before {
              border-color: transparent transparent transparent #fff;
            }
          }
        }
      }
      li {
        a {
          width: 100%;
          display: inline-block;
          @include hov-alpha;
          article {
            figure {
              margin-bottom: 30px;
              img {
                width: 100%;
              }
            }
            h3 {
              @include fz(18);
              font-weight: 500;
              line-height: 1.8;
              color: #000;
              @include mq(sp) {
                @include fz(13);
              }
            }
          }
        }
      }
      &__hd {
        margin-bottom: 25px;
        @include flex("c", "fs");
        @include mq(sp) {
          margin-bottom: 15px;
        }
        time {
          @include fz(16);
          font-weight: 500;
          color: #666;
          margin-right: 20px;
          @include mq(sp) {
            @include fz(13);
            margin-right: 10px;
          }
        }
        &__group {
          font-weight: 500;
          line-height: 1;
          color: $col_main;
          border: 1px solid $col_main;
          padding: 5px 15px;
          margin-right: 15px;
          @include mq(sp) {
            @include fz(10);
            padding: 3px 10px;
            margin-right: 10px;
          }
        }
        &__theme {
          @include fz(16);
          font-weight: 500;
          color: $col_main;
          @include mq(sp) {
            @include fz(10);
          }
        }
      }
    }
    &__col3 {
      flex: 1;
      @include mq(sp) {
        flex: none;
        width: 100%;
      }
    }
    &__list {
      li {
        a {
          width: 100%;
          display: inline-block;
          @include hov-alpha;
          article {
            @include flex("", "fs");
            @include mq(1205) {
              display: block;
            }
            @include mq(sp) {
              display: flex;
            }
            figure {
              width: 45.3488%;
              margin-right: 4.6512%;
              @include mq(1205) {
                width: 100%;
                margin: 0 0 10px 0;
              }
              @include mq(sp) {
                width: 27.8571%;
                margin-right: 2.8571%;
              }
            }
            > div {
              flex: 1;
              h3 {
                @include fz(16);
                font-weight: 500;
                line-height: 2;
                @include mq(sp) {
                  @include fz(12);
                  line-height: 1.5;
                }
              }
            }
          }
        }
        &:not(:last-child) {
          margin-bottom: 45px;
          @include mq(sp) {
            margin-bottom: 20px;
          }
        }
      }
      &__hd {
        margin-bottom: 10px;
        @include flex("c", "fs");
        time {
          width: 100%;
          @include fz(16);
          font-weight: 500;
          color: #666;
          margin-bottom: 10px;
          @include mq(sp) {
            width: auto;
            @include fz(13);
            margin: 0 10px 0 0;
          }
        }
        &__group {
          font-weight: 500;
          line-height: 1;
          color: $col_main;
          border: 1px solid $col_main;
          padding: 5px 15px;
          margin-right: 15px;
          @include mq(sp) {
            @include fz(10);
            padding: 3px 10px;
            margin-right: 10px;
          }
        }
        &__theme {
          @include fz(16);
          font-weight: 500;
          color: $col_main;
          @include mq(sp) {
            @include fz(10);
          }
        }
      }
    }
  }
}
