&insights {
  $insights: &;

  background: $col_bg;
  padding: 80px 0 60px;
  margin-bottom: -60px;
  @include mq(sp) {
    padding-top: 60px;
    padding-bottom: 50px;
    margin-bottom: -50px;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 30px;
    @include mq(sp) {
      grid-template-columns: 1fr;
      gap: 30px;
    }
    li {
      a {
        width: 100%;
        height: 100%;
        background: #fff;
        padding-bottom: 75px;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.19);
        position: relative;
        display: inline-block;
        @include mq(sp) {
          padding-bottom: 50px;
        }
        article {
          figure {
            margin-bottom: 30px;
            img {
              width: 100%;
              aspect-ratio: 76 / 47;
              object-fit: cover;
            }
          }
          h3 {
            @include fz(18);
            font-weight: 500;
            line-height: 1.8;
            color: #000;
            @include mq(sp) {
              @include fz(13);
            }
          }
        }
        &:hover {
          #{$insights} {
            &__list {
              &__btn {
                color: #fff;
                background: #666;
                &::after {
                  border-color: transparent transparent transparent #fff;
                }
              }
            }
          }
        }
      }
    }
    &__body {
      padding: 0 20px;
      @include mq(sp) {
        padding: 0 15px;
      }
    }
    &__hd {
      margin-bottom: 25px;
      @include flex("c", "fs");
      @include mq(sp) {
        margin-bottom: 15px;
      }
      time {
        @include fz(16);
        font-weight: 500;
        color: #666;
        margin-right: 20px;
        @include mq(sp) {
          @include fz(13);
          margin-right: 10px;
        }
      }
      &__group {
        @include fz(16);
        font-weight: 500;
        line-height: 1;
        color: #fff;
        background: $col_main;
        border: 1px solid $col_main;
        padding: 5px 15px;
        margin-right: 15px;
        @include mq(sp) {
          @include fz(13);
          padding: 3px 10px;
          margin-right: 10px;
        }
      }
      &__theme {
        @include fz(16);
        font-weight: 500;
        color: $col_main;
        @include mq(sp) {
          @include fz(13);
        }
      }
    }
    &__btn {
      @include fz(16);
      font-weight: 500;
      color: #666;
      background: #eee;
      padding: 10px 20px;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      @include flex("c", "fs");
      transition:
        background 0.2s,
        color 0.2s;
      @include mq(sp) {
        @include fz(13);
        padding: 5px 10px;
      }
      &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0 6px 8px;
        border-color: transparent transparent transparent #666;
        margin-left: 10px;
        transition: border-color 0.2s;
        @include mq(sp) {
          border-width: 3px 0 3px 4px;
        }
      }
    }
  }

  &--dtl {
    background: #fff;
    margin-bottom: -120px;
    &__hd {
      padding-bottom: 60px;
      margin-bottom: 80px;
      position: relative;
      z-index: 0;
      @include flex("", "fs");
      @include mq(sp) {
        padding: 0 20px 30px;
        margin-bottom: 60px;
        display: block;
      }
      &::after {
        content: "";
        width: 100%;
        height: 195px;
        background: #9ce1ca;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        @include mq(sp) {
          height: 80px;
        }
      }
      > figure {
        width: 38%;
        margin-right: 3.125%;
        @include mq(sp) {
          width: 100%;
          margin-right: 0;
        }
      }
      > div {
        flex: 1;
        padding-right: 3.125%;
        margin-top: 30px;
        @include mq(sp) {
          padding-right: 0;
        }
        time {
          @include fz(24);
          font-weight: 500;
          color: $col_main;
          margin-bottom: 25px;
          display: inline-block;
          @include mq(sp) {
            @include fz(19);
            margin-bottom: 15px;
          }
        }
        h1 {
          @include fz(26);
          font-weight: 500;
          line-height: 1.7;
          margin-bottom: 80px;
          @include mq(sp) {
            @include fz(20);
            margin-bottom: 40px;
          }
        }
      }
      &__cat {
        @include flex("c", "fs");
        li {
          @include fz(18);
          font-weight: 500;
          color: #fff;
          background: $col_main;
          padding: 5px 15px;
          @include mq(sp) {
            @include fz(14);
            padding: 3px 10px;
          }
          &:not(:last-child) {
            margin-right: 20px;
            @include mq(sp) {
              margin-right: 10px;
            }
          }
        }
      }
    }
    &__anchor {
      counter-reset: anchor;
      margin-bottom: 100px;
      li {
        counter-increment: anchor;
        a {
          background: linear-gradient(to right, #048c8d 0%, #53bb99 100%);
          padding: 30px 40px;
          @include flex("c", "fs");
          @include mq(sp) {
            padding: 15px 20px;
          }
          &::before {
            content: counter(anchor, decimal-leading-zero) ".";
            @include fz(38);
            font-weight: 700;
            color: #ffeea8;
            margin-right: 25px;
            transition: font-size 0.2s;
            @include mq(sp) {
              @include fz(24) margin-right: 15px;
            }
          }
          &::after {
            content: "";
            width: 12px;
            height: 12px;
            border-right: 3px solid #fff;
            border-bottom: 3px solid #fff;
            border-radius: 3px;
            margin-left: 25px;
            display: inline-block;
            transform: rotate(45deg);
            @include mq(sp) {
              width: 8px;
              height: 8px;
            }
          }
          span {
            flex: 1;
            @include fz(18);
            font-weight: 500;
            color: #fff;
            @include mq(sp) {
              @include fz(14);
            }
          }
          &:hover {
            background: #52ba99;
            &::before {
              @include fz(30);
              @include mq(sp) {
                @include fz(18);
              }
            }
          }
        }
        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }
    }
    &__content {
      counter-reset: anchor;
      > section {
        &:nth-of-type(even) {
          h2 {
            &::after {
              right: auto;
              left: 0;
            }
          }
        }
      }
      h2 {
        counter-increment: anchor;
        @include fz(24);
        font-weight: 500;
        padding-bottom: 20px;
        margin: 100px 0 55px;
        position: relative;
        @include flex("fs", "fs");
        @include mq(sp) {
          @include fz(19);
          padding-bottom: 10px;
          margin: 60px 0 40px;
        }
        &::before {
          content: counter(anchor, decimal-leading-zero) ".";
          @include fz(38);
          font-weight: 700;
          line-height: 1.1;
          color: $col_main;
          margin-right: 25px;
          @include mq(sp) {
            @include fz(24);
            line-height: 1.2;
            margin-right: 15px;
          }
        }
        &::after {
          content: "";
          width: 500%;
          height: 1px;
          background: $col_main;
          position: absolute;
          right: 0;
          bottom: 0;
          display: inline-block;
        }
        span {
          flex: 1;
          font-weight: inherit;
        }
      }
      h3 {
        @include fz(20);
        font-weight: 700;
        color: $col_main;
        margin: 55px 0 20px;
        @include mq(sp) {
          @include fz(16);
          margin-top: 30px;
        }
      }
      p {
        @include fz(16);
        line-height: 1.75;
        margin-bottom: 45px;
        @include mq(sp) {
          @include fz(13);
        }
        a {
          color: $col_main;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
      figure {
        margin: 50px 0;
        @include flex("c", "c");
        flex-direction: column;
        figcaption {
          @include fz(16);
          font-weight: 500;
          line-height: 1.75;
          margin-bottom: 20px;
          @include mq(sp) {
            @include fz(13);
          }
        }
        cite {
          @include fz(14);
          margin-top: 10px;
          @include mq(sp) {
            @include fz(10);
          }
        }
      }
    }
    &__writer {
      background: linear-gradient(33deg, #46b389 0%, #4ebcb0 100%);
      padding-top: 60px;
      padding-bottom: 60px;
      @include mq(sp) {
        padding-top: 40px;
        padding-bottom: 40px;
      }
      &__inr {
        min-height: 350px;
        background: #fff;
        @include flex("", "fs");
        @include mq(sp) {
          min-height: unset;
          display: block;
        }
        > figure {
          width: 24.6154%;
          @include mq(sp) {
            width: 100%;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        > div {
          flex: 1;
          padding: 30px 30px 30px 50px;
          @include flex("fs", "c");
          flex-direction: column;
          @include mq(sp) {
            flex: none;
            padding: 20px;
          }
        }
      }
      &__ttl {
        @include fz(18);
        font-weight: 500;
        margin-bottom: 10px;
        @include mq(sp) {
          @include fz(14);
        }
      }
      &__name {
        @include fz(30);
        font-weight: 700;
        color: $col_main;
        margin-bottom: 5px;
        @include mq(sp) {
          @include fz(21);
        }
      }
      &__affiliation {
        margin-bottom: 35px;
      }
    }
    &__other {
      background: $col_bg;
      padding-top: 60px;
      padding-bottom: 60px;
      &__inr {
        > ul {
          margin-bottom: 30px;
          li {
            margin-bottom: 0;
          }
        }
      }
      &__ttl {
        @include fz(30);
        font-weight: 700;
        color: $col_main;
        margin-bottom: 30px;
        @include mq(sp) {
          @include fz(21);
        }
      }
    }
  }
}
