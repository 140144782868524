&post {
  // common
  &-ttl {
    background: transparent linear-gradient(to left, #53bc99 0%, #2fa794 75%, #2aa493 85%, #008c8d 100%);
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: -13px;
    @include mq(sp) {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: -7px;
    }
    h2 {
      @include fz(24);
      font-weight: 500;
      color: #fff;
      @include mq(sp) {
        @include fz(16);
      }
    }
  }
}
// -----------------------------------------
