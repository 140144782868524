&form {
  $form: &;

  @include mq(sp) {
    margin-top: 40px;
  }
  table {
    table-layout: fixed;
    @include mq(sp) {
      display: block;
    }
    tbody {
      @include mq(sp) {
        display: block;
      }
      tr {
        @include mq(sp) {
          display: block;
        }
        th {
          width: 215px;
          vertical-align: top;
          padding: 10px 30px 10px 0;
          @include mq(sp) {
            width: 100%;
            padding: 0;
            margin-bottom: 10px;
            display: block;
          }
          > div {
            @include fz(16);
            font-weight: 700;
            @include flex("c", "fs");
            span {
              @include fz(14);
              font-weight: 500;
              line-height: 1;
              color: #fff;
              background: $col_main;
              padding: 5px 10px;
              margin-left: auto;
              display: inline-block;
              @include mq(sp) {
                @include fz(13);
                padding: 5px;
                margin-left: 20px;
              }
            }
          }
        }
        td {
          @include fz(16);
          vertical-align: top;
          padding: 10px 0;
          @include mq(sp) {
            width: 100%;
            padding: 0;
            display: block;
          }
          input {
            width: 100%;
            @include fz(16);
            background: #f5f5f5;
            border: none;
            padding: 15px;
            @include mq(sp) {
              padding: 10px;
            }
          }
          textarea {
            width: 100%;
            min-height: 250px;
            @include fz(16);
            background: #f5f5f5;
            border: none;
            padding: 15px;
            resize: vertical;
            @include mq(sp) {
              min-height: 150px;
              padding: 10px;
            }
          }
        }
        + tr {
          @include mq(sp) {
            margin-top: 30px;
          }
        }
      }
    }
  }
  &__privacy {
    text-align: center;
    margin-top: 45px;
    @include mq(sp) {
      text-align: left;
      margin-top: 40px;
    }
    p {
      @include fz(16);
      @include mq(sp) {
        @include fz(13);
      }
      a {
        color: $col_main;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .mwform-checkbox-field {
      @include fz(16);
      margin-top: 30px;
      display: inline-block;
      @include mq(sp) {
        @include fz(13);
      }
      input[type="checkbox"] {
        vertical-align: 0;
        @include mq(sp) {
          vertical-align: -1px;
        }
      }
    }
  }
  &__btn {
    margin-top: 45px;
    @include flex("c", "c");
    @include mq(sp) {
      margin-top: 40px;
    }
    > button {
      &[name="submitBack"] {
        @include fz(18);
        font-weight: 500;
        text-align: center;
        color: #fff;
        background-color: #888;
        border: 1px solid #888;
        border-radius: 25px;
        padding: 10px 30px;
        margin-right: 60px;
        position: relative;
        z-index: 0;
        @include inline-flex("c", "fs");
        overflow: hidden;
        @include mq(sp) {
          @include fz(13);
          padding-right: 20px;
          padding-left: 20px;
          margin-right: 30px;
        }
        &::before {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 8px 6px 0;
          border-color: transparent #fff transparent transparent;
          margin: 3px 15px 0 0;
          @include mq(sp) {
            border-width: 4px 6px 4px 0;
            margin: 1px 10px 0 0;
          }
        }
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          background: #fff;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          display: inline-block;
          transform: scale(0, 1);
          transform-origin: right top;
          transition: transform 0.2s;
        }
        &:hover {
          color: #888;
          &::before {
            border-color: transparent #888 transparent transparent;
          }
          &::after {
            transform: scale(1, 1);
            transform-origin: left top;
          }
        }
      }
    }
  }

  .mw_wp_form_input {
    table {
      tbody {
        tr {
          th {
            padding-top: 25px;
            padding-bottom: 0;
            @include mq(sp) {
              padding: 0;
            }
          }
        }
      }
    }
  }
  .mw_wp_form_confirm {
    #{$form} {
      &__privacy {
        display: none;
      }
    }
  }
}
