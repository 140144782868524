&about {
  $about: &;

  background: $col_bg;
  padding: 80px 0 60px;
  margin: -120px 0 -60px;
  @include mq(sp) {
    padding-top: 60px;
    padding-bottom: 50px;
    margin: -60px 0 -50px;
  }
  &__greeting {
    margin-top: 90px;
    @include mq(sp) {
      margin-top: 40px;
    }
    h3 {
      @include fz(30);
      font-weight: 500;
      color: $col_main;
      margin-bottom: 30px;
      @include mq(sp) {
        @include fz(21);
        margin-bottom: 15px;
      }
    }
    ul {
      margin: -40px 0 0 -4.6875%;
      @include flex("", "fs");
      @include mq(1024) {
        margin: 0;
        display: block;
      }
      li {
        width: calc((100% / 2) - 4.6875%);
        background: #fff;
        box-shadow: 3px 1px 18px rgba(91, 115, 110, 0.2);
        padding: 40px 50px 30px;
        margin: 40px 0 0 4.6875%;
        @include mq(1024) {
          width: 100%;
          margin-left: 0;
        }
        @include mq(sp) {
          padding: 20px 25px 30px;
          margin: 0;
        }
        &:not(:last-child) {
          @include mq(sp) {
            margin-bottom: 30px;
          }
        }
        &:nth-child(3) {
          #{$about} {
            &__greeting {
              &__hd {
                &__logo {
                  @include mq(sp) {
                    width: 150px;
                  }
                }
              }
            }
          }
        }
      }
    }
    &__hd {
      margin-bottom: 25px;
      @include flex("", "");
      @include mq(pc) {
        min-height: 190px;
      }
      &__logo {
        @include mq(sp) {
          width: 100px;
        }
      }
      &__img {
        width: 150px;
        height: 190px;
        background: #aaa;
        @include mq(sp) {
          width: 95px;
          height: 125px;
        }
      }
    }
    &__ttl {
      @include fz(26);
      font-weight: 700;
      margin-bottom: 15px;
      @include mq(sp) {
        @include fz(16);
        margin-bottom: 10px;
      }
    }
    &__txt {
      @include fz(16);
      line-height: 1.75;
      @include mq(sp) {
        @include fz(13);
      }
    }
    &__sign {
      @include fz(15);
      text-align: right;
      margin-top: 1.5em;
      @include mq(sp) {
        @include fz(12);
      }
    }
  }
}
