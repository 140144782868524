@include mq(pc) {
  &tab,
  &sp {
    display: none !important;
  }

  &pc-none {
    display: none !important;
  }
}

@include mq(tab) {
  &pc,
  &sp {
    display: none !important;
  }

  &tab-none {
    display: none !important;
  }
}

@include mq(sp) {
  &pc,
  &tab {
    display: none !important;
  }

  &sp-none {
    display: none !important;
  }
}