&contact {
  &::before {
    content: "";
    width: 100%;
    height: 13px;
    background: linear-gradient(to left, #53bc99 0%, #2fa794 75%, #2aa493 85%, #008c8d 100%);
    position: absolute;
    top: -13px;
    left: 0;
    display: inline-block;
    @include mq(sp) {
      height: 7px;
      top: -7px;
    }
  }
}
