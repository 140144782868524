@for $i from 0 through 20 {
  &mt#{$i * 5} {
    margin-top: #{$i * 5}px !important;
  }
  &mb#{$i * 5} {
    margin-bottom: #{$i * 5}px !important;
  }
}

@include mq(tab) {
  @for $i from 0 through 20 {
    &t-mt#{$i * 5} {
      margin-top: #{$i * 5}px !important;
    }
    &t-mb#{$i * 5} {
      margin-bottom: #{$i * 5}px !important;
    }
  }
}

@include mq(sp) {
  @for $i from 0 through 20 {
    &s-mt#{$i * 5} {
      margin-top: #{$i * 5}px !important;
    }
    &s-mb#{$i * 5} {
      margin-bottom: #{$i * 5}px !important;
    }
  }
}