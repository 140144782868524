&related {
  ul{
    li {
      @include fz(16);
      text-indent: -1em;
      padding-left: 1em;
      @include mq(sp) {
        @include fz(13);
      }
      &::before {
        content: "・";
      }
      a {
        text-decoration: underline;
        color: $col_main;
        &:hover {
          text-decoration: none;
        }
      }
      &:not(:last-child) {
        margin-bottom: 1.5em;
      }
    }
  }
}
