// メディアクエリ
@mixin mq($device: null) {
  @if $device == "pc" {
    @media print,screen and (min-width: #{nth($breakpoint_list, 2) + 1}) {
      @content;
    }
  } @else if $device == "tab" {
    @media screen and (min-width: #{nth($breakpoint_list, 1) + 1}) and (max-width: #{nth($breakpoint_list, 2)}) {
      @content;
    }
  } @else if $device == "sp" {
    @media screen and (min-width: 1px) and (max-width: #{nth($breakpoint_list, 1)}) {
      @content;
    }
  } @else {
    @media screen and (max-width: $device + px) {
      @content;
    }
  }
}

@mixin mq-min($device: null) {
  @media screen and (min-width: $device + px) {
    @content;
  }
}

// コンテナクエリ
@mixin container($name) {
  container: $name / inline-size;
}
@mixin cq($name, $size) {
  @container #{$name} (max-width: #{$size}px) {
    @content;
  }
}

// IEハック
@mixin hack($IE-ver: null) {
  @if $IE-ver == "gteIE11" {
    // IE11以上
    @at-root _:-ms-fullscreen,
      :root & {
      @content;
    }
  }
}

// Safariハック
@mixin safari {
  @at-root _::-webkit-full-page-media,
    _:future,
    :root & {
    @content;
  }
}

// フォントサイズ
@mixin fz($size, $base: 10) {
  font-size: $size + px;
  font-size: ($size / $base) + rem;
}

// flexbox
@mixin flex($ai, $jc) {
  display: flex;
  flex-wrap: wrap;

  @if ($ai == "") {
    align-items: stretch;
  }
  @if ($ai == "c") {
    align-items: center;
  }
  @if ($ai == "fs") {
    align-items: flex-start;
  }
  @if ($ai == "fe") {
    align-items: flex-end;
  }
  @if ($jc == "") {
    justify-content: space-between;
  }
  @if ($jc == "fs") {
    justify-content: flex-start;
  }
  @if ($jc == "fe") {
    justify-content: flex-end;
  }
  @if ($jc == "c") {
    justify-content: center;
  }
}
@mixin inline-flex($ai, $jc) {
  display: inline-flex;
  flex-wrap: wrap;

  @if ($ai == "") {
    align-items: stretch;
  }
  @if ($ai == "c") {
    align-items: center;
  }
  @if ($ai == "fs") {
    align-items: flex-start;
  }
  @if ($ai == "fe") {
    align-items: flex-end;
  }
  @if ($jc == "") {
    justify-content: space-between;
  }
  @if ($jc == "fs") {
    justify-content: flex-start;
  }
  @if ($jc == "fe") {
    justify-content: flex-end;
  }
  @if ($jc == "c") {
    justify-content: center;
  }
}

// clearfix
@mixin clearfix() {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

// css animation
@mixin keyframes($animation-name: animaton) {
  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin animation($animation-name, $animation-duration, $animation-delay: null) {
  animation: $animation-name;
  animation-duration: $animation-duration + s;
  animation-delay: $animation-delay + s;
  animation-fill-mode: both;
}

/* 汎用スタイル */
// wrapper-box
@mixin wrapper-box {
  padding-right: 30px;
  padding-left: 30px;

  @include mq(sp) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

// inner-box
@mixin inner-box($max-width: 1280) {
  max-width: $max-width + px;
  margin: 0 auto;
}

// hov-alpha
@mixin hov-alpha {
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.7;
  }
}
