&tal {
  text-align: left;
}

&tac {
  text-align: center;
}

&tar {
  text-align: right;
}