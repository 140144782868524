// グローバル
&gnavi {
  @include mq(1024) {
    width: 100%;
    max-height: calc(100dvh - 100%);
    background: $col_main;
    padding: 25px 25px 50px;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    transition: opacity 0.2s;
  }
  &__list {
    @include flex("c", "fs");
    @include mq(1024) {
      display: block;
    }
    > li {
      @include fz(18);
      @include mq(1024) {
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.4);
        padding: 20px 0;
      }
      @include mq(sp) {
        @include fz(17);
      }
      > a {
        font-weight: 500;
        position: relative;
        display: inline-block;
        @include mq(1024) {
          color: #fff;
        }
        &::after {
          content: "";
          width: 100%;
          height: 1px;
          background: $col_main;
          position: absolute;
          bottom: -5px;
          left: 0;
          display: inline-block;
          transform: scale(0, 1);
          transform-origin: right top;
          transition: transform 0.2s;
          @include mq(1024) {
            content: none;
          }
        }
        &:hover {
          &::after {
            transform: scale(1, 1);
            transform-origin: left top;
          }
        }
      }
      &:not(:last-child) {
        margin-right: 50px;
        @include mq(1125) {
          margin-right: 30px;
        }
        @include mq(1024) {
          margin-right: 0;
        }
      }
      &.is-current {
        a {
          @include mq(pc) {
            font-weight: 700;
            color: $col_main;
          }
          &::after {
            @include mq(pc) {
              transform: scale(1, 1);
              transform-origin: left top;
            }
          }
        }
      }
    }
    &__chd {
      margin: 15px 0 0 1em;
      li {
        @include fz(13);
        a {
          color: rgba($color: #fff, $alpha: 0.8);
          display: inline-block;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}

// パンくず
&breadcrumb {
  padding: 15px;
  padding-bottom: 15px;
  &--bgc {
    background: $col_bg;
  }
  ol {
    @include flex("c", "fs");
    li {
      @include fz(16);
      @include flex("c", "fs");
      &:not(:last-child) {
        &::after {
          content: "\FF1E";
          color: #888;
          margin: 0 10px;
          display: inline-block;
        }
      }
      a {
        color: #888;
        display: inline-block;
        span {
          color: inherit;
        }
        &:hover {
          span {
            text-decoration: underline;
          }
        }
      }
      span {
        color: $col_main;
        display: inline-block;
      }
      &.home {
        img {
          width: 18px;
          height: 18px;
          vertical-align: -0.1em;
        }
      }
    }
  }
}
