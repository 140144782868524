&btn {
  @include fz(18);
  font-weight: 500;
  text-align: center;
  color: $col_main;
  background-color: #fff;
  border: 1px solid $col_main;
  border-radius: 25px;
  padding: 10px 30px;
  position: relative;
  z-index: 0;
  @include inline-flex("c", "fs");
  overflow: hidden;
  @include mq(sp) {
    @include fz(13);
    padding-right: 20px;
    padding-left: 20px;
  }
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background: $col_main;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: inline-block;
    transform: scale(0, 1);
    transform-origin: right top;
    transition: transform 0.2s;
  }
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 8px;
    border-color: transparent transparent transparent $col_main;
    margin: 3px 0 0 15px;
    @include mq(sp) {
      border-width: 4px 0 4px 6px;
      margin: 1px 0 0 10px;
    }
  }
  &:hover {
    color: #fff;
    &::before {
      transform: scale(1, 1);
      transform-origin: left top;
    }
    &::after {
      border-color: transparent transparent transparent #fff;
    }
  }
  &--rev {
    color: #fff;
    background-color: $col_main;
    &::before {
      background: #fff;
    }
    &::after {
      border-color: transparent transparent transparent #fff;
    }
    &:hover {
      color: $col_main;
      &::after {
        border-color: transparent transparent transparent $col_main;
      }
    }
  }
}
