&news {
  padding-top: 120px;
  @include mq(sp) {
    padding-top: 60px;
  }
  &__inr {
    @include flex("", "");
    @include mq(sp) {
      display: block;
    }
    > div {
      flex: 1;
      margin-right: 9.375%;
      @include mq(sp) {
        flex: none;
        width: 100%;
        margin-right: 0;
      }
    }
  }
  &__list {
    li {
      border-bottom: 1px solid #aaa;
      a {
        padding-bottom: 25px;
        display: inline-block;
        @include mq(sp) {
          padding-bottom: 10px;
        }
        @include hov-alpha;
        article {
          h3 {
            @include fz(18);
            font-weight: 500;
            font-weight: inherit;
            line-height: 1.8;
            @include mq(sp) {
              @include fz(13);
            }
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 40px;
        @include mq(sp) {
          margin-bottom: 20px;
        }
      }
    }
    &__hd {
      margin-bottom: 20px;
      @include flex("c", "fs");
      @include mq(sp) {
        margin-bottom: 10px;
      }
      time {
        @include fz(16);
        font-weight: 500;
        color: #666;
        margin-right: 20px;
        @include mq(sp) {
          @include fz(13);
          margin-right: 10px;
        }
      }
      &__cat {
        @include fz(16);
        font-weight: 500;
        line-height: 1;
        color: $col_main;
        border: 1px solid $col_main;
        padding: 5px 15px;
        @include mq(sp) {
          @include fz(10);
          padding: 3px 10px;
        }
      }
    }
    &--col {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px 30px;
      @include mq(sp) {
        grid-template-columns: 1fr;
        gap: 20px;
      }
      li {
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }

  &--dtl {
    position: relative;
    &::before {
      content: "";
      width: 100%;
      height: 13px;
      background: linear-gradient(to left, #53bc99 0%, #2fa794 75%, #2aa493 85%, #008c8d 100%);
      position: absolute;
      top: -13px;
      left: 0;
      display: inline-block;
      @include mq(sp) {
        height: 7px;
        top: -7px;
      }
    }
    &__hd {
      margin-bottom: 20px;
      @include flex("c", "fs");
      @include mq(sp) {
        margin-bottom: 10px;
      }
      time {
        @include fz(16);
        font-weight: 500;
        color: #666;
        margin-right: 20px;
        @include mq(sp) {
          @include fz(13);
          margin-right: 10px;
        }
      }
      &__cat {
        @include fz(16);
        font-weight: 500;
        line-height: 1;
        color: $col_main;
        border: 1px solid $col_main;
        padding: 5px 15px;
        @include mq(sp) {
          @include fz(10);
          padding: 3px 10px;
        }
      }
    }
    h1 {
      @include fz(18);
      font-weight: 500;
      font-weight: inherit;
      line-height: 1.8;
      border-bottom: 1px solid #aaa;
      padding-bottom: 30px;
      margin-bottom: 40px;
      @include mq(sp) {
        @include fz(13);
        padding-bottom: 20px;
        margin-bottom: 30px;
      }
    }
    &__content {
      h2 {
        @include fz(20);
        font-weight: 700;
        color: $col_main;
        margin-bottom: 20px;
        @include mq(sp) {
          @include fz(16);
        }
      }
      p {
        @include fz(16);
        line-height: 1.75;
        margin-bottom: 45px;
        @include mq(sp) {
          @include fz(13);
        }
      }
      // dummy
      figure {
        float: left;
        width: 48.4375%;
        height: 340px;
        background: #f5f5f5;
        margin: 0 2.7344% 2.7344% 0;
        @include mq(sp) {
          float: none;
          width: 100%;
          height: 200px;
          margin: 0 0 30px 0;
        }
      }
    }
    &__link {
      width: 220px;
      margin: 80px auto 0;
      @include mq(sp) {
        margin-top: 60px;
      }
      a {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
