&content {
  overflow: hidden;
}

&sidebar {
  width: 240px;
  @include mq(sp) {
    width: 100%;
    margin-top: 60px;
  }
  &--news {
    &__cat {
      &__ttl {
        @include fz(20);
        font-weight: 500;
        padding: 0 0 15px 10px;
        margin-bottom: 20px;
        position: relative;
        z-index: 0;
        @include mq(sp) {
          @include fz(16);
          padding-bottom: 10px;
          margin-bottom: 15px;
        }
        &::after {
          content: "";
          width: 500%;
          height: 1px;
          background: $col_main;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: -1;
        }
      }
      &__list {
        margin-left: 10px;
        > li {
          @include fz(18);
          @include mq(sp) {
            @include fz(14);
          }
          > a {
            width: 100%;
            font-weight: 500;
            padding: 0 10px 0 5px;
            @include inline-flex("c", "");
            &::after {
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 6px 0 6px 8px;
              border-color: transparent;
              display: inline-block;
              transition:
                color 0.2s,
                border-color 0.2s;
              @include mq(sp) {
                border-width: 3px 0 3px 4px;
              }
            }
            &:hover {
              color: $col_main;
              &::after {
                border-color: transparent transparent transparent $col_main;
              }
            }
          }
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
