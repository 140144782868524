&ttl {
  &--type01 {
    @include fz(24);
    font-weight: 500;
    line-height: 1.25;
    color: $col_main;
    margin-bottom: 35px;
    @include mq(sp) {
      @include fz(13);
      margin-bottom: 25px;
    }
    span {
      @include fz(48);
      font-weight: inherit;
      color: $col_text;
      display: inline-block;
      @include mq(sp) {
        @include fz(40);
      }
    }
  }
  &--type02 {
    @include fz(24);
    font-weight: 500;
    padding-bottom: 20px;
    margin-bottom: 25px;
    position: relative;
    @include mq(sp) {
      @include fz(19);
      padding-bottom: 10px;
      margin-bottom: 15px;
    }
    &::after {
      content: "";
      width: 500%;
      height: 1px;
      background: $col_main;
      position: absolute;
      right: 0;
      bottom: 0;
      display: inline-block;
    }
  }
  &--type03 {
    @include fz(20);
    font-weight: 700;
    color: $col_main;
    margin-bottom: 20px;
    @include mq(sp) {
      @include fz(16);
    }
  }
}
